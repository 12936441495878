export default function date_to_br_in_full(value) {
    if(value === null) {
        return ''
    }

    let { 0: date, 1: time } = value.split(' ');
    if(typeof time === 'undefined') {
        value = `${date} 03:00:00`
    }

    return new Date(value).toLocaleDateString('pt-br', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}